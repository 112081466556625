@import ../../../styles/helpers

.info
    margin-bottom: 32px
    +body-bold-1

.group
    display: flex
    flex-direction: column
    align-items: flex-start

.checkbox
    &:not(:last-child)
        margin-bottom: 24px

.btns
    display: flex
    margin-top: 24px
    .button
        &:not(:last-child)
            margin-right: 12px
