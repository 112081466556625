@import ../../../styles/helpers

.control
    display: flex
    justify-content: space-between
    margin-bottom: 64px
    +m
        display: block
        margin-bottom: 48px

.back
    display: flex
    align-items: center
    +poppins
    +body-bold-1
    +dark
        color: $n8
    svg
        margin-right: 16px
        fill: $n4
        transition: transform .2s
    &:hover
        svg
            transform: translateX(-2px)

.money
    display: flex
    align-items: center
    +body-bold-2
    +m
        display: none
    img
        margin-left: 12px
        width: 24px

.payment
    display: flex
    justify-content: center
    align-items: flex-start

.field
    position: relative

.value,
.input
    +dm-sans
    font-weight: 700
    font-size: 96px
    line-height: 1
    letter-spacing: -.02em
    color: $n2
    +m
        font-size: 64px

.value
    min-width: 72px
    max-width: 500px
    min-height: 96px
    padding-right: 5px
    opacity: 0
    +m
        min-height: 64px
        max-width: 280px
        padding-bottom: 10px

.input
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border: none
    background: none
    box-shadow: none
    color: $n2
    +dark
        color: $n8

.sign
    position: relative
    top: 8px
    margin-right: 8px
    +m
        top: 1px

.price
    margin-bottom: 12px
    text-align: center
    +body-bold-2
    span
        margin-left: 8px
        color: $n4

.variants
    display: flex
    justify-content: center
    flex-wrap: wrap
    margin: -8px -8px 0
    .button
        margin: 8px 8px 0
        color: $n4
        &:hover
            color: $n8

.btns
    margin-top: 48px
    text-align: center
    +m
        margin-top: 32px
    .button
        min-width: 184px