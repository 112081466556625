@import ../../styles/helpers

.container
    max-width: 1132px

.head
    max-width: 545px
    margin: 0 auto 80px
    text-align: center
    +t
        margin-bottom: 48px
    +m
        margin-bottom: 24px

.logo
    margin-bottom: 16px

.title
    margin-bottom: 20px

.info
    +body-2
    color: $n4