@import ../../../styles/helpers

.card
    display: flex
    flex-direction: column
    align-items: flex-start
    padding-bottom: 48px
    border-bottom: 2px solid $n6
    color: $n2
    transition: color .2s
    +m
        padding-bottom: 0
        border: none
    +dark
        border-color: $n3
        color: $n8
    &:hover
        color: $p1
        .preview
            img
                transform: scale(1.1)

.preview
    position: relative
    overflow: hidden
    width: 100%
    margin-bottom: 48px
    border-radius: 12px
    +m
        margin-bottom: 32px
    &:before
        content: ""
        display: block
        padding-bottom: 57%
        +d
            padding-bottom: 75%
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
        transition: transform 1s

.category
    margin-bottom: 16px
    
.title
    margin-bottom: 48px
    +body-bold-1
    +m
        margin-bottom: 32px

.line
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    width: 100%
    margin-top: auto
    font-weight: 500
    color: $n4

.author
    display: flex
    align-items: center
    margin-right: 16px

.avatar
    flex-shrink: 0
    width: 24px
    height: 24px
    margin-right: 12px
    border-radius: 50%
    overflow: hidden
    background: $p4
    img
        width: 100%
        height: 100%
        object-fit: cover