@import ../../styles/helpers

.title
    margin-bottom: 20px
    +body-bold-1

.form
    position: relative
    flex-shrink: 0
    margin-bottom: 40px

.input
    width: 100%
    height: 48px
    padding: 0 48px 0 14px
    border-radius: 8px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-2
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $p1

.result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 48px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1

.table
    display: table
    width: 100%

.row
    display: table-row
    color: $n2
    &:first-child
        .col
            padding-top: 0
            padding-bottom: 24px
            +caption-bold-2
            color: $n4
            svg
                margin-left: 4px
                fill: $n4
    &:not(:first-child)
        cursor: pointer
        .col
            border-top: 1px solid $n6
            vertical-align: middle
            font-weight: 500
            +dark
                border-color: $n3
            &:first-child
                color: $n4
        &:hover
            .subtitle
                color: $p1

.col
    display: table-cell
    padding: 16px
    text-align: right
    font-weight: 500
    +dark
        color: $n8
    &:first-child,
    &:nth-child(2)
        text-align: left
    &:first-child
        font-size: 12px
        padding-left: 0
        +m
            display: none
    &:last-child
        padding-right: 0
        +m
            display: none

.line
    display: flex
    align-items: center

.favorite
    position: relative
    top: -1px
    margin-right: 10px

.item
    display: flex
    align-items: center

.icon
    flex-shrink: 0
    width: 32px
    margin-right: 12px
    img
        max-width: 100%

.subtitle
    margin-right: 4px
    transition: color .2s

.currency
    font-weight: 400
    color: $n4

.positive
    color: $p4

.negative
    color: $p3
