@import ../../styles/helpers

.switch
    display: inline-block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    font-size: 0

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0
    &:checked + .inner .box
        background: $p1
        &:before
            transform: translate(20px,-50%)
            background: $n8

.inner
    position: relative
    display: inline-block
    transition: all .2s

.box
    position: relative
    display: block
    width: 40px
    height: 20px
    background: $n6
    border-radius: 12px 
    transition: all .2s
    +dark
        background: $n3
    &:before
        content: ""
        position: absolute
        top: 50%
        left: 4px
        transform: translateY(-50%)
        width: 12px
        height: 12px
        border-radius: 50%
        background: $p1
        transition: all .2s 