@import ../../styles/helpers

.export
    position: relative

.body
    position: absolute
    top: calc(100% + 16px)
    right: 0
    z-index: 2
    width: 364px
    padding: 32px
    background: $n8
    border: 1px solid $n6
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12)
    border-radius: 16px
    text-align: center
    visibility: hidden
    opacity: 0
    transition: all .2s
    +m
        width: 311px
        padding: 16px 0 24px
    +dark
        background: $n2
        border-color: $n3
    &.show
        visibility: visible
        opacity: 1
    .button
        margin-top: 24px

.interval
    margin-top: 8px
    +body-bold-2