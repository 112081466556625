@import ../../../styles/helpers

.box
    display: flex
    margin-top: 32px
    background: $n7
    border-radius: 16px
    +m
        margin-bottom: -32px
        background: none
    +dark
        background: $n2
        +m
            background: none

.details
    max-width: 256px
    margin: 64px auto 0
    padding: 32px 48px
    border-radius: 32px 32px 0 0
    background: $n8
    +m
        margin-top: 0
        background: $n7
    +dark
        background: $n3

.code
    padding: 16px
    border-radius: 8px
    border: 2px dashed $p1
    img
        width: 100%
        border-radius: 8px

.button
    width: 100%
    margin-top: 48px
    +dark
        box-shadow: inset 0 0 0 2px $n4
        &:hover
            box-shadow: inset 0 0 0 2px $n5