@import ../../../styles/helpers

.wrap
    text-align: center

.title
    margin-bottom: 8px
    text-align: center

.info
    margin-bottom: 32px
    text-align: center
    +body-2