@import ../../../styles/helpers

.item
    display: flex
    +m
        padding-left: 32px
        padding-right: 32px
    &:not(:last-child)
        margin-bottom: 32px
        padding-bottom: 24px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3
    &.active
        .status
            display: block

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 16px
    border-radius: 50%
    border: 2px solid $n6
    +dark
        border-color: $n3
    svg
        fill: $n4

.line
    display: flex
    margin-bottom: 8px
    +m
        flex-wrap: wrap

.title
    margin-right: auto
    padding-right: 32px
    +body-bold-2
    +m
        flex: 0 0 100%
        margin: 0 0 8px
        padding-right: 0

.date
    flex-shrink: 0
    margin-top: 2px
    +caption-2
    color: $n4
    +m
        margin: 0 auto 0 0

.status
    display: none
    flex-shrink: 0
    width: 12px
    height: 12px
    margin: 5px 0 0 16px
    border-radius: 50%
    background: $p4
    +m
        margin-top: 3px

.content
    +caption-2
    color: $n4