@import ../../../styles/helpers

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    color: $n2
    +t
        display: flex
        flex-wrap: wrap
    &:first-child
        +t
            display: none
        .col
            padding: 0 16px 24px
            border-bottom: 1px solid $n6
            +caption-bold-2
            color: $n4
            +dark
                border-color: $n3
            &:first-child
                +d
                    padding-left: 0
            &:last-child
                +d
                    padding-right: 0
    &:not(:first-child)
        transition: background .2s
        .col
            vertical-align: middle
            font-weight: 500
            &:first-child
                border-radius: 12px 0 0 12px
                color: $n4
                +t
                    border-radius: 0
            &:last-child
                border-radius: 0 12px 12px 0
                +t
                    border-radius: 0
        &:hover
            background: $n7
            +t
                background: none
            +dark
                background: $n2
                +t
                    background: none
    &:not(:last-child)
        +t
            margin-bottom: 32px
            padding-bottom: 32px
            border-bottom: 1px solid $n6
            +dark
                border-color: $n3

.col
    display: table-cell
    padding: 20px 16px
    font-weight: 500
    +t
        padding: 0
        display: flex
        align-items: center
    +dark
        color: $n8
    &:first-child
        +d
            padding-left: 0
    &:nth-child(2)
        +t
            margin-left: auto
    &:nth-child(5),
    &:nth-child(6)
        color: $n4
    &:last-child
        text-align: right
        +d
            padding-right: 0
    &:not(:first-child):not(:nth-child(2))
        +t
            flex: 0 0 100%
            margin-top: 8px

.item
    display: flex
    align-items: center

.icon
    flex-shrink: 0
    width: 32px
    margin-right: 12px
    img
        max-width: 100%

.label
    display: none
    +t
        display: block
        margin-right: auto
        +caption-bold-2
        color: $n4