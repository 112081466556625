@import ../../../styles/helpers

.title
    margin-bottom: 16px
    span
        color: $p3

.text
    margin-bottom: 40px
    +caption-2
    color: $n4
    span
        font-weight: 600
        color: $n2
        +dark
            color: $n8

.email
    display: flex
    align-items: center
    margin-bottom: 24px
    +body-bold-2
    svg
        margin-right: 12px
        fill: $n2
        +dark
            fill: $n8

.subtitle
    margin-bottom: 8px
    +body-bold-1

.info
    margin-bottom: 24px
    color: $n3
    +dark
        color: $n5

.box
    display: flex
    margin-top: 32px
    background: $n7
    border-radius: 16px
    +m
        margin-top: 24px
    +dark
        background: $n2

.details
    max-width: 256px
    margin: 64px auto 0
    padding: 32px 48px
    border-radius: 32px 32px 0 0
    background: $n8
    +dark
        background: $n3
    .button
        width: 100%
        margin-top: 48px

.code
    padding: 16px
    border-radius: 8px
    border: 2px dashed $p1
    img
        width: 100%
        border-radius: 8px

.btns
    margin-top: 32px
    text-align: center
    +m
        margin-top: 24px
        .button
            width: 100%