@import ../../../styles/helpers

.notifications
    position: relative
    +m
        position: static
    &.active
        .head
            svg
                fill: $n2
                +dark
                    fill: $n6
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.head
    position: relative
    width: 40px
    height: 40px
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $n2
            +dark
                fill: $n6
    &.active
        &:before
            content: ""
            position: absolute
            top: 0
            right: 0
            width: 12px
            height: 12px
            border-radius: 50%
            background: $p4

.body
    position: absolute
    top: calc(100% + 20px)
    right: -70px
    width: 286px
    padding: 16px
    border-radius: 24px
    box-shadow: 0px 16px 64px -24px rgba(31, 47, 70, 0.15)
    background: $n8
    visibility: hidden
    opacity: 0
    transform: translateY(10px)
    transition: all .3s
    +m
        top: 100%
        left: 16px
        right: 16px
        width: auto
    +dark
        background: $n2
    &:before
        content: ""
        position: absolute
        right: 78px
        bottom: 100%
        width: 24px
        height: 12px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='9' fill='none' viewBox='0 0 24 9'%3E%3Cpath d='M6.343 2.657L0 9h24l-6.343-6.343a8 8 0 0 0-11.314 0z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat 50% 100% / 100% auto
        +m
            right: 111px
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='9' fill='none' viewBox='0 0 24 9'%3E%3Cpath d='M6.343 2.657L0 9h24l-6.343-6.343a8 8 0 0 0-11.314 0z' fill='%2323262F'/%3E%3C/svg%3E")

.title
    margin-bottom: 4px
    +body-bold-1

.item
    position: relative
    display: block
    padding: 12px 0
    color: $n2
    transition: color .2s
    +dark
        color: $n8
    &:hover
        color: $p1
    &.new
        padding-right: 24px
        &:after
            content: ""
            position: absolute
            top: 16px
            right: 0
            width: 12px
            height: 12px
            border-radius: 50%
            background: $p4
    &:not(:last-child)
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.subtitle
    +caption-bold-2

.date
    +caption-3
    font-weight: 500
    color: $n4

.btns
    display: flex
    margin: 4px -8px 0
    .button
        flex: 0 0 calc(50% - 16px)
        width: calc(50% - 16px)
        margin: 0 8px
